// get_insurance_product_list 获取保险产品列表
const __request = require(`@/lib/data-service/yinzhilv-temporary-storage-system/__request/__request_contentType_json`);

// 接口文档地址：
export default function get_insurance_product_list(pParameter) {
    let params = {}
    if (!pParameter) pParameter = {
        typeCode: ''
    };
    let query = {
        query: `query {
            allInsuranceProducts(where: {type: {code: "${pParameter.typeCode}"}, enable: true}) {
                title,
                type{code, title},
                content,
                age,
                term,
                price,
                link
            }
        }`
    }
    params.method = 'POST'
    params.urlSuffix = '/admin/api'
    params.data = query
    return new Promise(function(resolve, reject) {
        resolve(__request(params))
    })
}