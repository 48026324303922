// get_insurance_enumeration 保险类型枚举
const __request = require(`@/lib/data-service/yinzhilv-temporary-storage-system/__request/__request_contentType_json`);

// 接口文档地址：
export default function get_insurance_enumeration(pParameter) {
    let params = {};
    if (!pParameter) pParameter = {};
    let query = {
        query: `query {
          allInsuranceProductTypes {
            code,
            title
          }
        }`
    };
    params.method = 'POST';
    params.urlSuffix = '/admin/api';
    params.data = query;
    return new Promise(function(resolve, reject) {
        resolve(__request(params));
    });
}