const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

const admin_api = (pParameter) => {
    if (!pParameter) pParameter = {};
    const query = pParameter.query;

    const p = {
        method: 'post',
        urlSuffix: '/admin/api',
        data: {
            query,
        },
    };
    return Q.when()
        .then(function () {
            return __request(p);
        })
        .then(function (data) {
            return Q.resolve(data);
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};
module.exports = admin_api;
