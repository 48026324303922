import _ from "underscore";
import join from "nllib/src/uri/join";
import get_url_prefix from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix";
import LoginCore from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/login-core/1.0.2/index.vue";
import set_token from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/set_token/index";

import Footer from "./components/footer/index.vue";
import Header from "@/page/supplier/component/header/1.0.3/index.vue";
import web_common_advert_showList from '@/lib/data-service/default/web_common_advert_showList'
import web_common_source_getPermissions from "@/lib/common-service/web_common_source_getPermissions";

import admin_api from "@/lib/data-service/yinzhilv-temporary-storage-system/admin_api";
import get_insurance_product_list from '@/lib/data-service/yinzhilv-temporary-storage-system/get_insurance_product_list' // 获取保险产品列表
import get_insurance_enumeration from '@/lib/data-service/yinzhilv-temporary-storage-system/get_insurance_enumeration' // 获取保险产品类型枚举
import get_insurance_link from '@/lib/data-service/yinzhilv-temporary-storage-system/get_insurance_link' // 获取保险产品的link
import get_app_config from '@/lib/data-service/yinzhilv-temporary-storage-system/get_app_config' // 根据key值取appConfig的内容
export default {
    data() {
        return {
            tabNum: 0,
            swiperList: [
                // "https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2020-09-27/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20200927163046.jpg?x-oss-process=style/yinzhilv-default",
                // "https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2020-07-10/focus.jpg?x-oss-process=style/yinzhilv-default",
                // "https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2020-07-10/focus4.jpg?x-oss-process=style/yinzhilv-default",
                // "https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2020/11/27/1.jpg?x-oss-process=style/yinzhilv-default",
                // "https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2020/11/19/%E5%B9%BF%E4%B8%9C%E6%B8%A9%E6%B3%89.jpg?x-oss-process=style/yinzhilv-default", // 广东温泉
                // "https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2020/11/19/%E5%8C%97%E4%BA%AC.jpg?x-oss-process=style/yinzhilv-default", // 北京
                // "https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2020/11/19/%E4%B8%9C%E5%8C%97%E6%B8%B8.jpg?x-oss-process=style/yinzhilv-default", // 东北游
                // "https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2020/11/19/%E4%BA%91%E5%8D%97.jpg?x-oss-process=style/yinzhilv-default", // 云南
                // "https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2020/11/19/%E5%8D%8E%E4%B8%9C%E6%9C%80%E7%BE%8E%E4%BA%94%E5%9F%8E.jpg?x-oss-process=style/yinzhilv-default", //华东
            ],
        };
    },
    components: {LoginCore, Footer, Header},
    created() {
    },
    mounted() {
        const __this = this;

        this.getBanner();

        __this.$refs.aLoginCore.init({
            login_successfully_event_handler(data) {
                // const environment = global.appConfig.environment;

                const token = data.token;
                const userType = data.userType;

                // 供应商
                if (_.contains([1, 2], userType)) {
                    const url_prefix = get_url_prefix({
                        appKey: `frontend-supplier`,
                    });
                    const url = join(
                        url_prefix,
                        `redirect/?redirectType=100&token=${token}`
                    );
                    window.location.href = url;
                    return;
                }

                // 分销商，临时分销渠道
                if (_.contains([3, 4, 7], userType)) {
                    set_token({token: token});

                    web_common_source_getPermissions({
                        clear_cache: true,
                    });

                    __this.$router.push({
                        name: `redirect-to-user-front-home`,
                    });
                    return;
                }

                // 平台
                if (_.contains([5], userType)) {
                    const url_prefix = get_url_prefix({
                        appKey: `frontend-platform`,
                    });
                    const url = join(
                        url_prefix,
                        `redirect/?redirectType=000&token=${token}`
                    );
                    window.location.href = url;
                    return;
                }

                // 游客
                if (_.contains([6], userType)) {
                    const url = `/general-user`;
                    window.location.href = url;
                    return;
                }
            },
        });

        const query = `query {
  allPosts{
    id,
    title,
    content,
    postType{
      id,
      code,
      text,
    }
    createdAtFormat,
    updatedAtFormat,
  },
}`;
        // admin_api({query}).then(function (data) {
        //   console.log(data);
        // });
        // get_insurance_enumeration().then(res => {
        //   console.log(res)
        // })
        // get_insurance_product_list({typeCode: '1'}).then(res => {
        //   console.log('555', res)
        // })
        // get_insurance_link({id: '5fbb80684646b10fc0d51606'}).then(res => {
        //   console.log(res)
        // })
        // get_app_config({key: 'yinzhilv_insurance_search'}).then(res => {
        //   console.log(res)
        // })

    },
    activated() {
        const __this = this;
        __this.form.userName = ``;
        __this.form.userPassword = ``;
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        __btn_supplier_registration_click() {
            const __this = this;
            __this.$router.push({
                name: `supplier-registration`,
            });
        },

        __btn_distributor_registration_click() {
            const __this = this;
            __this.$router.push({
                name: `distributor-registration`,
            });
        },
        clickTab(num) {
            this.tabNum = num;
        },
        to_advantage() {
            const {href} = this.$router.resolve({
                name: "distributor-advantage",
            });

            window.open(href, "_blank");
        },
        // 获取banner
        getBanner() {

            let compare = function (obj1, obj2) {

                let val1 = obj1.advertSortNo;
                let val2 = obj2.advertSortNo;
                if (val1 < val2) {
                    return -1;
                } else if (val1 > val2) {
                    return 1;
                } else {
                    return 0;
                }
            };
            web_common_advert_showList({advertPictureType: 1}).then(res => {
                // let swiperList = res.advertList;
                // swiperList = swiperList.sort(compare);

                this.swiperList = res.advertList;
            })
        }
    },
};
