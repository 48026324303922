// get_app_config 获取保险
const __request = require(`@/lib/data-service/yinzhilv-temporary-storage-system/__request/__request_contentType_json`);

// 接口文档地址：
export default function get_app_config(pParameter) {
    let params = {}
    if (!pParameter) pParameter = {
        key: ''
    };
    let query = {
        query: `query {
          allAppConfigs(where: {key: "${pParameter.key}"}) {
            value
          }
        }`
    }
    params.method = 'POST'
    params.urlSuffix = '/admin/api'
    params.data = query
    return new Promise(function(resolve, reject) {
        resolve(__request(params))
    })
}