// web_common_advert_showList banner展示接口
const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`)
export default function web_common_advert_showList(val) {

    var pParameter = {};
    pParameter.method = "POST";
    pParameter.urlSuffix = "/web/common/advert/showList";
    pParameter.data = val;
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}