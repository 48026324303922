
import get_operation_guide_title_two_common from "@/lib/common-service/get_operation_guide_title_2";
const _ = require(`underscore`);
export default {
  data() {
    return {
      clearMarginTop: true,
      // type：1、文字，2、图片
      navList: [
        {
          title: "关于我们",
          items: [
            {
              name: "胤之旅简介",
              type: "1",
              href: "/distributor/aboutUs?type=0",
            },
            {
              name: "合作伙伴",
              type: "1",
              href: "/distributor/aboutUs?type=1",
            },
            { name: "网站声明", type: "1", href: "/distributor/aboutUs?type=2" },
            { name: "知识产权声明", type: "1", href: "/distributor/aboutUs?type=3" },
          ],
        },
        {
          title: "联系我们",
          items: [
            { name: "商务合作", type: "1", href: "/distributor/contactUs?type=0" },
            { name: "投诉建议", type: "1", href: "/distributor/contactUs?type=1" },
            { name: "诚聘精英", type: "1", href: "/distributor/contactUs?type=2" },
          ],
        },
        {
          title: "帮助中心",
          items:[],
        },
        {
          title: "资质证书",
          items: [
            {
              name: "实名认证",
              type: "2",
              href:
                "//si.trustutn.org/info?sn=673230322040940798779&certType=1",
              url:
                "https://v.trustutn.org/images/cert/bottom_large_img.png",
            },
          ],
        },
      ],
      OfficialAccountsList: [
        {
          title: "微信公众号",
          url:
            "//yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/default-v2/distributor/ewm.jpg",
        },
      ],
      UrlHelpCenter:[],
    };
  },
  created() {
    if (
      !this.$route.meta.footer_margin_none &&
      this.$route.name !== "distributor-train-front-home" &&
      this.$route.name !== "distributor-train-front-pay-success"
    ) {
      this.clearMarginTop = false;
    } else {
      this.clearMarginTop = true;
    }
  },
  watch: {
    $route: {
      handler: function(newValue, oldValue) {
        if (
          !newValue.meta.footer_margin_none &&
          newValue.name !== "distributor-train-front-home" &&
          newValue.name !== "distributor-train-front-pay-success"
        ) {
          this.clearMarginTop = false;
        } else {
          this.clearMarginTop = true;
        }
      },
      deep: true,
    },
  },
  mounted(){
    get_operation_guide_title_two_common().then((data) => {
      const list = _.chain(data.data.allYinzhilvHelpCenterLists).groupBy(function (m) {
        return m.menuLevelFirstName;
      }).map(function (property_value, property_name) {
        const list = property_value;
        const menuLevelFirstName = property_name;
        const first = list[0];
        const menuLevelFirstIndex = first.menuLevelFirstIndex;

        return {
          menuLevelFirstName,
          menuLevelFirstIndex,
          list,
        };
      }).sortBy(function (m) {
        return m.menuLevelFirstIndex;
      }).value();

      list.forEach((item) => {
        this.UrlHelpCenter.push(item);
      });

      //对一级目录进行排序
      this.UrlHelpCenter = this.goSortdata(this.UrlHelpCenter,'menuLevelFirstIndex');

      //对二级目录进行排序
      this.UrlHelpCenter.forEach((item) => {
        item.list = this.goSortdata(item.list,'menuLevelSecondIndex');
      });

      this.UrlHelpCenter.forEach((item,index) => {
        //使用排序好的数据，重新插入navList
        this.navList[2].items.push({
          name: item.menuLevelFirstName,
          type: '1',
          href: `/help-center?id=${this.UrlHelpCenter[index].list[0].id}`
        });
      });
    });
  },
  methods: {
    goRefresh(val) {
      let { href } = this.$router.resolve({
        name: "distributor-aboutUs",
        query: { type: val },
      });
      window.open(href);
    },
    goIssue(val) {
      let { href } = this.$router.resolve({
        name: "distributor-FAQ",
        query: { type: val },
      });
      window.open(href);
    },
    goContacts(val) {
      let { href } = this.$router.resolve({
        name: "distributor-contactUs",
        query: { type: val },
      });
      window.open(href);
    },
    goSortdata(data,basisName) {
      let dataTest = data.sort((a,b) => {
        if (eval(`a.${basisName}`) < eval(`b.${basisName}`)) {
          return -1;
        } else if (eval(`a.${basisName}`) < eval(`b.${basisName}`)) {
          return 0;
        } else  {
          return 1;
        }
      });
      return dataTest;
    },
  },
};
