// get_insurance_link 获取保险的链接
const __request = require(`@/lib/data-service/yinzhilv-temporary-storage-system/__request/__request_contentType_json`);

// 接口文档地址：
export default function get_insurance_link(pParameter) {
    let params = {}
    if (!pParameter) pParameter = {
        id: ''
    };
    let query = {
        query: `query {
          InsuranceProduct(where: {id: "${pParameter.id}"}) {
            link
          }
        }`
    }
    params.method = 'POST'
    params.urlSuffix = '/admin/api'
    params.data = query
    return new Promise(function(resolve, reject) {
        resolve(__request(params))
    })
}